<template>
    <div class="login site__centered">

        <form
                class="form"
                @submit.prevent="submitResetPasswordForm"
        >
            <div class="form__wrap login__form">
                <h2 class="form__title"> Reset Password</h2>
                <p class="form__subtitle">Your new password must be different from previously used passwords</p>
              <label class="form__row">
                <span class="form__row-label">New Password *</span>
                <div class="form__row-pass">
                  <input
                    v-model="formData.password"
                    class="input"
                    :type="typePassword"
                    placeholder="******"
                    :class="{'is-invalid': $v.formData.password.$dirty &&
                            (!$v.formData.password.required || !$v.formData.password.minLength) }"
                  />
                  <span class="form__row-pass-show" @click="showPassword()">Show</span>
                </div>
              </label>

              <label class="form__row">
                <span class="form__row-label">Confirm Password *</span>
                <div class="form__row-pass">
                  <input
                    v-model="formData.passwordRepeat"
                    class="input"
                    :type="typePassword"
                    placeholder="******"
                    :class="{'is-invalid': $v.formData.passwordRepeat.$dirty &&
                            (!$v.formData.passwordRepeat.required || !$v.formData.passwordRepeat.minLength|| !$v.formData.passwordRepeat.sameAsPassword )}"
                  />
                  <span class="form__row-pass-show" @click="showPassword()">Show</span>
                </div>
              </label>

              <div
                v-if="validationErrors.length"
                class="form-group"
              >
                <p
                  v-for="(validationError, index) in validationErrors"
                  :key="index"
                  class="text-center form__error"
                >
                  {{ validationError }}
                </p>
              </div>

                <button
                  class="btn btn--color-dark form__button"
                  :class="{ 'btn--loading': isLoading }"
                >
                  <span v-if="!isLoading">Save Password</span>
                </button>
                <span class="center">Or</span>
                <router-link
                    :to="{name: 'login'}"
                    class="btn btn--color-outline-bright form__button"
                >Back to login</router-link>

            </div>
        </form>

        <!-- <welcome-block class="mobile-hidden"/> -->
    </div>
</template>

<script>
import {required, minLength, sameAs} from 'vuelidate/lib/validators'

  import useResetPasswordSetting from '@/views/useResetPasswordSetting'

  export default {
    data() {
      return {
        userEmail: '',
        userToken: '',
        formData: {
          password: null,
          passwordRepeat: null,
        },
        isLoading: false,
        validationErrors: [],
        typePassword: 'password',
      }
    },
    validations: {
      formData: {
        password: {
          required,
          minLength: minLength(6)
        },
        passwordRepeat: {
          required,
          minLength: minLength(6),
          sameAsPassword: sameAs('password'),
        },
      }
    },
    setup() {
      const {
        resetPassword,
      } = useResetPasswordSetting()

      return {
        resetPassword,
      }
    },
    async created() {
      this.userToken = this.$route.params.token
      this.userEmail = this.$route.params.email
    },
    methods: {
      showPassword() {
        this.typePassword = this.typePassword === 'password' ? 'text' : 'password'
      },
      async submitResetPasswordForm() {
        if (this.$v.$invalid){
          await this.$v.$touch();
          document.querySelector('.is-invalid').focus()
          this.errorMessage = 'All fields are required'
          return
        }

        this.isLoading = true

        const preparedData = {
          password: this.formData.password,
          password_confirmation: this.formData.passwordRepeat,
          token: this.userToken,
          email: this.userEmail,
        }
        this.resetPassword(preparedData)
          .then(() => {
            this.validationErrors = []
            this.$router.push({ name: 'auth-login' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              const validationErrors = Object.values(error.response.data.errors)
              this.validationErrors = validationErrors.flat()
            } else {
              console.log(error)
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>

<style>
</style>
